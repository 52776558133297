<template>
  <div class="container">
    <div class="header-container">
      <header>
      <van-nav-bar
          :title="title"
          left-text="返回"
          right-text=""
          left-arrow
          @click-left="onBack"
      />
      </header>
    </div>
    <div class="notice-content">
        “{{title}}”业务请<span style="font-weight:bold">通过微信</span>关注“昆明自来水公司”微信公众号进行办理！谢谢！
    </div>
    <div class="qrcode-content">
        <img class="qrcode-official" src="../../assets/images/qrcode.jpg">
    </div>
    <div class="footer-container">
      <footer class="bz-footer">
        <van-button round block type="info"  @click="onBack">返回</van-button>
      </footer>
    </div>
  </div>
</template>
<script>
import Loading from "../../components/logding";
import {bzRead} from "../../api/compulsive";

export default {
  name: "bzRead",
  components: { Loading },
  data(){
    return{
      id: this.$route.query.id,
      url: this.$route.query.url+'?xzid='+this.$route.query.id,
      isHidFooter: parseInt(this.$route.query.isFooter || '0') == 1 ? false : true,
      isNext: false,
      second: 5,
      lodingTxt: '加载中...',
      content:'',
      title:'昆明自来水公众号',
    }
  },
  created() {
    this.title=this.$route.query.title;
  },
  methods: {
    onBack : function (){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
  .notice-content{
    font-size:16px;
    line-height:30px;
    text-indent:25px;
    letter-spacing:1px;
    padding:10px;
  }
  .qrcode-content{
    width:100%;
    text-align:center;
  }
  .qrcode-official{
    width:300px;
  }
  .bz-footer{
    border-top: 0rem;
    margin-bottom:10px;
  }
</style>
